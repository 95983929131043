import React from 'react';
import axios from 'axios';
import moment from "moment-timezone";

export const error = (err) => {
    if (err.response && (err.response.status === 503)) {
        window.location.reload();
    } else if (err.response && (err.response.status === 403)) {
        // do not log authorization errors
    } else {
        let error;

        if (err instanceof Error) {
            error = JSON.stringify(err, Object.getOwnPropertyNames(err), 2);
        } else {
            error = {
                note: 'this was not an error object',
                error: err
            };
        }

        if (process.env.NODE_ENV === 'production') {
            axios.post('/exp/error-log/client', {error: {error}})
                .then(() => null)
                .catch(err => window.console && console.log(err));
        }


    }
    window.console && console.log(err.response ? err.response : err);
}

export const undefinedObject = (object) => {
    return object ? object : {}
};

export const undefinedString = (string) => {
    return string ? string : ''
};

export const timeString = (h, m, a = 'am') => {
    const meridiem = (a.toLowerCase().includes('a')) ? 'am' : 'pm';
    let hour = Number(h);
    let minute = Number(m);

    if (meridiem === 'pm' && hour !== 12) {
        hour += 12;
    }

    if (meridiem === 'am' && hour === 12) {
        hour += 12;
    }

    if (minute < 10) {
        minute = '0' + minute.toString();
    } else {
        minute = minute.toString();
    }

    if (hour < 10) {
        hour = '0' + hour.toString();
    } else if (hour === 24) {
        hour = '00';
    } else {
        hour = hour.toString();
    }

    return (hour + ':' + minute + ':00');
};

export const getDateString = date => {

    let dateString = date.getFullYear().toString() + '-';
    dateString += ('0' + (date.getMonth() + 1).toString()).slice(-2) + '-';
    dateString += ('0' + date.getDate().toString()).slice(-2);

    return dateString;
};

export const getLocalJSDate = (date, timezone) => {
    const dateString = moment.tz(date, timezone).format('MM-DD-YYYY');

    return new Date(dateString);
};

export const htmlLineBreaks = (string) => {
    if (string) {
        return string.split('\n').map((el, index) => (
            <span key={index}>{el}<br/></span>
        ));
    } else {
        return '';
    }
};

export const numberToCurrencyString = (num = 0) => {
    // const neg = num < 0;
    //
    // if (neg) {
    // 	num = num * -1;
    // }
    //
    // let roundedNum = Math.round(num * 100) / 100;
    //
    // if (roundedNum.toString().split('.')[1] && roundedNum.toString().split('.')[1].length === 1) {
    // 	roundedNum = roundedNum.toString() + '0';
    // } else roundedNum = roundedNum.toString();
    //
    // return `${neg ? '-' : ''}$${roundedNum}`;

    return num.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
};

export const displayPercentage = (num, total) => {
    const percentage = num / total;

    if (isNaN(percentage)) {
        return (0).toLocaleString('en', {style: 'percent'});
    }

    return percentage.toLocaleString('en', {style: 'percent'});
}

export const htmlErrorHandler = (err, customMessage) => {
    error(err);
    if (customMessage) {
        err.customMessage = customMessage;
    }

    let errorObject;

    if (err instanceof Error) {
        errorObject = JSON.stringify(err, Object.getOwnPropertyNames(err), 2);
    } else {
        errorObject = err;
    }
    const status = (err.response && err.response.status) || 500;

    return {status, errorObject};
};

export const csvEscape = s => {
    return typeof (s) === 'string' ? s.replace(/"/g, '""') : s;

};

export const convertTaskDueDate = date => {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    return moment()
        .tz('America/Chicago')
        .set('year', year)
        .set('month', month)
        .set('date', day)
        .set('hour', 12)
        .toDate();
};

export const escapeRegExp = string => {
    if (string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    } else {
        return '';
    }
};

// adds trailing zero to field with currency
export const numberToCurrencyField = num => {
    if (!num) {
        return num;
    }

    const cents = num.toString().split('.')[1];

    if (cents && cents.length === 1) {
        return num.toString() + '0';
    }

    return num.toString();
};

// reassigns nested objects to completely new objects
export const object_assign = source => {
    const target = {};

    if (source && typeof (source) === 'object') {
        Object.keys(source).forEach((key) => {
            const s_val = source[key];
            if (Array.isArray(s_val)) {
                target[key] = s_val.map(element => object_assign(element));
            } else {
                target[key] = s_val;
            }
        });

        return target;
    } else {
        return source;
    }
}

// get a max weight warning based on pets ({_id: petMongoId, weight: number}), populated invoice
export const getMaxWeightWarning = (pets, invoice) => {
    const maxWeightWarning = [];

    invoice.lineItem.forEach(item => {
        if (item.service.maxWeight) {
            const topWeight = item.service.weightBreakPoints[item.service.weightBreakPoints.length - 1];
            const thisPet = pets.find(pet => pet._id === item.pet);

            // only error on weights that are above the maximum, not equal
            if (thisPet && thisPet.weight && thisPet.weight > topWeight) {
				maxWeightWarning.push({
                    service: item.service.name,
                    serviceId: item.service._id,
                    maxWeight: topWeight,
                    pet: thisPet._id
                });
            }
        }
    });

    return maxWeightWarning;
}

export const roundNumber = (number, decimalPlaces) => parseFloat(number).toFixed(decimalPlaces);
